<template>
  <div>
    <div class="mt-6 flex justify-between">
      <div class="font-bold text-darkblue">
        <h4 class="text-darkblue capitalize">{{ mode }}</h4>
      </div>
      <div>
        <button
          @click="TableButton"
          class="text-white font-bold text-xs  bg-darkblue md:pl-3 pl-2 pr-2 md:pr-3 pt-2 pb-2 rounded-lg"
        >
          <font-awesome-icon
            title="Add Invoice"
            :class="'text-white text-xs mr-1 '"
            :icon="['fas', 'plus']"
          />
          <span class="capitalize"> Add New {{ mode }}</span>
        </button>
      </div>
    </div>
    <div class="mt-6">
      <form
        @submit.prevent="SearchTable"
        class="h-full flex flex-col justify-between  lg:flex-row-reverse  "
        action=""
      >
        <div class="text-center lg:min-w-7/20 flex md:text-auto">
          <input
            v-model="searchQuery"
            title="Please input a search phrase"
            class="flex-grow  text-xs  inline h-11 md:h-9 pl-2 pr-2 focus:ring-2 focus:ring-green-600  rounded-l-lg pt-1 pb-1"
            placeholder="Search here"
          /><button
            :class="{ 'cursor-not-allowed': !searchFilter && !dateFilter }"
            class="bg-darkblue pt-1 pb-1 mb-3 text-white hover:bg-blue-600 focus:text-darkblue focus:bg-lightblue pl-2 pr-2  h-11 md:h-9"
            type="submit"
          >
            <i class="fas fa-search "></i>
          </button>
        </div>
        <div
          class="md:mr-2 flex flex-col md:flex-row lg:min-w-7/20  justify-between items-center  md:max-w-1/2 lg:max-w-full "
        >
          <span class="text-xs hidden md:inline">Filter:</span>
          <t-select
            fixedClasses="inline text-xs h-11 mb-3 md:mb-0 ml-2 mr-2 md:h-9 rounded-l-lg cursor-pointer"
            placeholder="Select Filter"
            v-model="searchFilter"
            :options="searchFilterOptions"
          ></t-select>

          <t-datepicker
            :classes="datePickerDefaultClass"
            :dateFormat="'Y-m-d H:i:S'"
            :userFormat="'Y-m-d'"
            :placeholder="'Select date range'"
            v-model="selectedDate"
            :range="true"
          />
        </div>
      </form>
    </div>

    <div>
      <Table
        @clicked-table="NavigateToSingleInvoice($event)"
        @show-alert="ShowAlert($event)"
        :table_data="table_data"
        :FormatTableData="FormatTableData"
        :mode="mode"
        :search_data="searchData"
        :TableButton="TableButton"
        :table_header="table_header"
      />
    </div>
  </div>
</template>

<script>
  import Table from "../../components/Table.vue";
  import { emitEventMixin } from "@/assets/helpers/emitEventMixin";
  import { mapMutations } from "vuex";

  import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

  import { datePickerMixin } from "@/assets/helpers/datePickerMixin";

  export default {
    name: "Invoices",
    props: {},
    mixins: [datePickerMixin, emitEventMixin],
    components: {
      Table,
      FontAwesomeIcon,
    },
    data() {
      return {
        searchFilterOptions: [
          { value: "customerName", text: "Customer Name" },
          { value: "status", text: "Status" },
        ],
        selectedDate: [],
        searchData: {
          model: "invoice",
          limit: 5,
          filter: null,
          searchQuery: null,
          dateFilter: null,
        },

        searchQuery: null,
        searchFilter: null,
        user_year_query: 2020,
        chart_loading: true,
        hide_add_new_button: true,

        mode: "invoice",
        card_data: [
          { label: "Total Stock", value: "20" },
          { label: "Low Stock", value: "10" },
          { label: "Exhausted Stock", value: "20" },
        ],
        table_header: [
          {
            id: "invoice-no",
            value: "invoice_no",
            text: "No.",
            className: "",
          },
          {
            value: "amount",
            text: "Amount(₦)",
            className: "fortunate-table",
          },
          {
            value: "customer_name",
            text: "Customer",
            className: "fortunate-table",
          },
          {
            value: "status",
            text: "Payment Status",
          },
          {
            value: "regDate",
            text: "Reg. Date",
          },
        ],

        table_data: [],
        //
        // paginated_table_data: [],
        icons: {
          pen: require("@/assets/imgs/icons/white-pen.svg"),
        },
      };
    },
    watch: {
      searchQuery: function(newValue) {
        if (!newValue) {
          this.RefreshTable();
        }
      },
      dateFilter: function(newValue) {
        if (!newValue) {
          this.RefreshTable();
        }
      },
    },
    computed: {
      dateFilter: function() {
        if (this.selectedDate.length > 0) {
          if (this.selectedDate.length > 1) {
            return {
              from: new Date(this.selectedDate[0]).getTime(),
              to: new Date(this.selectedDate[1]).getTime(),
            };
          }
          return {
            from: new Date(this.selectedDate[0]).getTime(),
            to: new Date(this.selectedDate[0]).getTime(),
          };
        }
        return null;
      },
    },
    methods: {
      ...mapMutations(["setBreadcrumbs"]),

      NavigateToSingleInvoice(id) {
        this.$router.push({ name: "single_invoice", params: { id: id } });
      },
      RefreshTable() {
        this.searchData.filter = null;
        this.searchQuery = "";
        this.searchData.searchQuery = null;
        this.searchData.dateFilter = null;
      },
      SearchTable() {
        if (!this.searchFilter && !this.dateFilter) {
          return this.$emit("show-alert", {
            type: "error",
            message: "Select a filter or  date range before you can search",
          });
        }

        this.searchData.filter = this.searchFilter;
        this.searchData.dateFilter = this.dateFilter;
        this.searchData.searchQuery = this.searchQuery;
      },
      FormatTableData(data) {
        this.table_data = data.map((invoice) => {
          const date = new Date(Number(invoice.createdAt));
          return {
            id: invoice._id,
            invoice_no: invoice.serialId,
            amount: invoice.invoiceTotal,
            customer_name: invoice.customerId.customerName,
            regDate: `${date.getDate()}/${date.getMonth() +
              1}/${date.getFullYear()}`,
            status: invoice.completedPayment === true ? "paid" : "pending",
            btn: "btn",
          };
        });
      },
      TableButton() {
        this.$router.push({ name: "manage_invoice", params: { mode: "new" } });
      },
    },
    mounted() {
      this.setBreadcrumbs([{ displayName: "Invoices", pathName: "invoices" }]);
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .custom-select.open .custom-select-options {
    display: block;
  }
  .custom-select li.selected {
    background-color: rgb(52, 211, 153);
  }
</style>
